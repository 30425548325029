var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.value)?_c('v-dialog',{attrs:{"value":_vm.value,"persistent":"","scrollable":"","max-width":_vm.maxWidth,"width":"100%"}},[_c('div',{staticClass:"ap-card d-flex flex-column"},[_c('div',{staticClass:"d-flex flex-column px-6 pt-4 pb-2"},[_c('div',{staticClass:"d-flex flex-no-wrap justify-space-between"},[_c('ApLogo'),(_vm.isCloseIconVisible)?_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{on:{"click":_vm.closeDialog}},[_vm._v(" $mdi-close ")])],1):_vm._e()],1),_c('div',{staticClass:"font-weight-medium text-h6",class:( _obj = {
          'text-center': _vm.isContentCentered
        }, _obj[_vm.titleColorClass] = !!_vm.titleColorClass, _obj )},[_vm._t("title",function(){return [_c('div',{staticClass:"mt-4"},[_vm._v(" "+_vm._s(_vm.title)+" ")])]})],2)]),(_vm.isDividersVisible)?_c('v-divider'):_vm._e(),_c('div',{staticClass:"white ap-dark-gray--text",class:{ 'text-center': _vm.isContentCentered },staticStyle:{"position":"relative","overflow-y":"auto"},style:({ 'max-height': _vm.maxContentHeight })},[_vm._t("content",function(){return [_c('div',{staticClass:"px-6"},_vm._l((_vm.contents),function(item,index){return _c('div',{key:index,staticClass:"mb-3"},[_vm._t(("content-" + index),function(){return [_vm._v(" "+_vm._s(item)+" ")]})],2)}),0)]}),_vm._t("append-content")],2),(_vm.isDividersVisible)?_c('v-divider'):_vm._e(),(_vm.actions.length > 0)?_c('div',{staticClass:"d-flex flex-column px-6 py-3 white"},[_c('div',{staticClass:"d-flex flex-wrap pb-0",class:{
          'justify-center': _vm.isContentCentered,
          'justify-end': !_vm.isContentCentered,
        }},_vm._l((_vm.actions),function(action){return _c('v-btn',_vm._b({key:action.event,staticClass:"ml-2",on:{"click":function($event){return _vm.emitAction(action)}}},'v-btn',Object.assign({}, action),false),[(action.leftIcon)?_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(action.leftIcon)+" ")]):_vm._e(),_vm._v(" "+_vm._s(action.label)+" "),(action.rightIcon)?_c('v-icon',{attrs:{"right":""}},[_vm._v(" "+_vm._s(action.rightIcon)+" ")]):_vm._e()],1)}),1),_vm._t("append-actions")],2):_vm._e()],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }