<template>
  <div class="body-2 font-weight-medium">
    <div class="mb-4">
      <div class="d-flex align-center" :style="{ 'min-height': '28px' }">
        <div>
          <span class="font-weight-bold" :class="{ 'text-h6': !isList }">
            {{ item.firstname }} {{ item.lastname }}
          </span>
        </div>
        <div class="flex-grow-1">
          <v-chip
            v-for="tag in item.tags"
            :key="tag.id"
            dark
            small
            color="green"
            class="ml-3"
          >
            {{ tag.value }}
          </v-chip>
        </div>

        <template v-if="isList">
          <div>
            <div class="primary--text ap-light-grey px-2">
              {{ item.entity }}
            </div>
          </div>
          <v-checkbox
            v-if="isSelectable"
            v-model="checked"
            :value="item.id"
            hide-details
            dense
            class="pa-0 ma-0 ml-1"
          />
        </template>
      </div>
      <div
        class="ap-dark-gray--text"
        :class="{ 'font-weight-medium': !isList }"
      >
        {{ item.position }}
      </div>
    </div>

    <div class="d-flex">
      <div style="flex: 2">
        <div class="mb-1 ap-dark-gray--text">{{ item.entity }} Details</div>
        <div>
          <v-icon small class="mr-1" color="grey"> $mdi-email </v-icon>
          <span>{{ item.contactDetails.email }}</span>
        </div>
        <div>
          <v-icon small class="mr-1" color="grey"> $mdi-phone </v-icon>
          <span>{{ item.contactDetails.phone }}</span>
        </div>
        <div>
          <v-icon small class="mr-1" color="grey"> $mdi-map-marker </v-icon>
          <span>{{ item.contactDetails.address }}</span>
        </div>
      </div>
      <div style="flex: 1">
        <div class="mb-1 ap-dark-gray--text">Linked Account</div>
        <div>
          <div v-for="account in item.linkedAccounts" :key="account.id">
            {{ account.value }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    isList: {
      type: Boolean,
      default: false,
    },
    isSelectable: {
      type: Boolean,
      default: false,
    },
    selectedIds: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    checked: {
      get() {
        return this.selectedIds
      },
      set(value) {
        this.$emit('selected-ids-changed', value)
      },
    },
  },
}
</script>

<style scoped>
::v-deep .v-input .v-input--selection-controls__input {
  margin: 0 !important;
}
</style>
