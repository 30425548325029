<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scroll-y-transition"
    offset-y
    origin="center"
    min-width="0"
  >
    <template #activator="{ on }">
      <v-text-field
        :value="formattedValue"
        class="white text-caption font-weight-bold"
        placeholder="Date"
        append-icon="$mdi-calendar-month"
        outlined
        dense
        hide-details
        v-on="on"
        @click:append="menu = true"
      />
    </template>
    <v-card color="white" width="350" class="date-range-content">
      <!-- Custom Range -->
      <div
        class="d-flex pa-4 justify-space-between align-center position-relative"
      >
        <DatePicker
          v-model="startDate"
          placeholder="Start"
          :textfield="true"
          @input="endDate = ''"
        />
        <div class="mx-4">
          <DatePicker
            v-model="endDate"
            placeholder="End"
            :textfield="true"
            :min="startDate"
          />
        </div>
        <v-btn outlined color="primary" height="40" @click="onDateSelection">
          ok
        </v-btn>
      </div>
      <!-- Existing Ranges -->
      <div>
        <div
          v-for="(item, i) in ranges"
          :key="i"
          class="pa-3 pl-6 font-weight-bold ap-dark-gray--text range-item text-caption"
          @click="setDates(item.value)"
        >
          {{ item.text }}
        </div>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import {
  parseISO,
  formatISO,
  format,
  subWeeks,
  startOfWeek,
  endOfWeek,
  startOfQuarter,
  endOfQuarter,
  subQuarters,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
} from 'date-fns'
import DatePicker from './DatePicker'

export default {
  components: {
    DatePicker,
  },
  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    modal: false,
    startDate: '',
    endDate: '',
    ranges: [
      { text: 'Today', value: 'today' },
      { text: 'This week', value: 'week' },
      { text: 'Last week', value: 'lastweek' },
      { text: 'This quarter', value: 'quarter' },
      { text: 'Last quarter', value: 'lastquarter' },
      { text: 'This month', value: 'month' },
      { text: 'This year', value: 'year' },
    ],
  }),
  computed: {
    formattedValue() {
      let { startDate, endDate } = this

      startDate = startDate ? format(parseISO(startDate), 'MMM dd, yyyy') : ''
      endDate = endDate ? format(parseISO(endDate), 'MMM dd, yyyy') : ''
      if (startDate && endDate) return `${startDate} - ${endDate}`
      else if (startDate || endDate) return startDate || endDate

      return ''
    },
  },
  methods: {
    setDates(domain) {
      if (domain === 'today') {
        this.startDate = this.endDate = new Date().toISOString()

        return
      }

      const today = new Date()

      switch (domain) {
        case 'week':
          this.startDate = formatISO(startOfWeek(today))
          this.endDate = formatISO(endOfWeek(today))
          break
        case 'lastweek':
          this.startDate = formatISO(startOfWeek(subWeeks(today, 1)))
          this.endDate = formatISO(endOfWeek(subWeeks(today, 1)))
          break
        case 'month':
          this.startDate = formatISO(startOfMonth(today))
          this.endDate = formatISO(endOfMonth(today))
          break
        case 'quarter':
          this.startDate = formatISO(startOfQuarter(today))
          this.endDate = formatISO(endOfQuarter(today))
          break
        case 'lastquarter':
          this.startDate = formatISO(startOfQuarter(subQuarters(today, 1)))
          this.endDate = formatISO(endOfQuarter(subQuarters(today, 1)))
          break
        case 'year':
          this.startDate = formatISO(startOfYear(today))
          this.endDate = formatISO(endOfYear(today))
          break
      }
    },
    onDateSelection() {
      this.$refs.menu.save()
      const { startDate, endDate } = this

      this.$emit('change', {
        startDate,
        endDate,
      })
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .date-range-content {
  .v-input__slot {
    padding: 0 6px !important;
  }

  input {
    font-size: 13px;
    color: var(--v-ap-dark-gray-base) !important;
  }

  .v-icon {
    font-size: 20px;
  }

  .v-input__icon {
    min-width: 20px;
    max-width: 20px;
  }

  .v-input__append-inner {
    padding-left: 0px !important;
  }

  .range-item {
    border-top: 1px solid var(--v-ap-grey-base);
    cursor: pointer;
    transition: all 0.25s;

    &:hover {
      background-color: var(--v-ap-light-grey-base);
    }
  }
}
</style>
