<template>
  <ApDialog
    :value="value"
    :actions="actions"
    max-width="420"
    @cancel="$emit('cancel')"
    @input="$emit('cancel', $event)"
    @confirm="$emit('confirm')"
  >
    <template #title>
      <div>
        You are about the accpet the new entry {{ item.entity }}
        <span class="primary--text">{{ item.name }}</span>
        {{ `(ID${item.id})` }}
      </div>
    </template>

    <template #content>
      <div class="px-6 py-3">
        <div v-if="userHasEditPermissions">
          <v-checkbox
            label="Don't show this message once again"
            color="ap-dark-blue"
            class="mt-0 pt-0"
            hide-details
          />
        </div>

        <div v-else>
          <p>
            The user {{ item.creator }} who created the record does not having
            editing permissions.
          </p>
          <v-checkbox
            label="Grant the user editing permissions"
            color="ap-dark-blue"
            class="mt-0 pt-0"
            hide-details
          />
        </div>
      </div>
    </template>
  </ApDialog>
</template>

<script>
import ApDialog from '@/components/common/ApDialog'

export default {
  components: {
    ApDialog,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {},
    },
    userHasEditPermissions: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    actions() {
      return [
        {
          event: 'cancel',
          label: 'Take me back',
          color: 'ap-dark-blue',
          outlined: true,
        },
        {
          event: 'confirm',
          label: 'Confirm',
          color: 'ap-dark-blue',
          dark: true,
        },
      ]
    },
  },
}
</script>
