<template>
  <div>
    <v-sheet
      v-for="(item, index) in items"
      :key="index"
      rounded
      outlined
      class="pa-4 mb-4"
    >
      <OnEntryDuplicatesToReviewListItemDialogMatchesListItem
        is-list
        :is-selectable="isSelectable"
        :selected-ids="value"
        :item="item"
        @selected-ids-changed="$emit('input', $event)"
      />
    </v-sheet>
  </div>
</template>

<script>
import OnEntryDuplicatesToReviewListItemDialogMatchesListItem from './OnEntryDuplicatesToReviewListItemDialogMatchesListItem'

export default {
  components: {
    OnEntryDuplicatesToReviewListItemDialogMatchesListItem,
  },
  props: {
    // selected Ids
    value: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    isSelectable: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
