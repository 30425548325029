import { render, staticRenderFns } from "./OnEntryDuplicatesToReviewListItemDialogMatchesListItem.vue?vue&type=template&id=45268e83&scoped=true&"
import script from "./OnEntryDuplicatesToReviewListItemDialogMatchesListItem.vue?vue&type=script&lang=js&"
export * from "./OnEntryDuplicatesToReviewListItemDialogMatchesListItem.vue?vue&type=script&lang=js&"
import style0 from "./OnEntryDuplicatesToReviewListItemDialogMatchesListItem.vue?vue&type=style&index=0&id=45268e83&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45268e83",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VCheckbox,VChip,VIcon})
