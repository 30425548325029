<template>
  <ApDialog
    :value="value"
    is-dividers-visible
    title="New entry that was bypassed"
    max-width="1200"
    :actions="actions"
    @input="$emit('input', $event)"
    @action="$emit($event)"
  >
    <template #content>
      <div class="d-flex body-2 pb-0 ap-black--text">
        <div class="left-column pb-6">
          <div class="px-6 pt-4">
            <div>
              <div class="font-weight-medium">
                Created by <span>{{ item.creator }}</span>
              </div>
              <div class="ap-dark-gray--text">
                {{ formatDate(item.created, 'MMMM dd, yyyy K:mm aaa') }}
              </div>
            </div>
          </div>

          <v-divider class="my-5" />

          <div class="px-6">
            <OnEntryDuplicatesToReviewListItemDialogMatchesListItem
              :item="item"
              class="mt-6"
            />
          </div>

          <v-divider class="my-5" />

          <div class="px-6">
            <div class="font-weight-medium">
              Is John Smythe a new record, or a dupe of one (or more) of the
              matches on the right?
            </div>
            <div>
              <v-radio-group v-model="recordType" row hide-details>
                <v-radio label="New record" value="new" />
                <v-radio label="Duplicate" value="duplicate" />
              </v-radio-group>
            </div>
          </div>

          <template v-if="isDuplicatedRecord">
            <v-divider class="my-5 mx-6" />

            <div class="px-6 font-weight-medium">
              Of which record(s) is this a duplicate? Select on the right
            </div>
          </template>
        </div>

        <div class="right-column py-4 pl-6">
          <div class="d-flex">
            <div class="flex-grow-1">
              <div class="font-weight-medium">Matches</div>
              <div class="body-2 font-weight-medium mb-1 ap-dark-gray--text">
                Select duplicate records from the list.
              </div>
            </div>
            <div>
              <v-img
                height="44"
                src="@/assets/images/activePrimeCleanEnter.png"
              />
            </div>
          </div>

          <OnEntryDuplicatesToReviewListItemDialogMatchesList
            v-model="selectedIds"
            :items="matches"
            :is-selectable="isDuplicatedRecord"
            class="pt-4"
          />
        </div>
      </div>
    </template>
  </ApDialog>
</template>

<script>
import OnEntryDuplicatesToReviewListItemDialogMatchesList from './OnEntryDuplicatesToReviewListItemDialogMatchesList'
import OnEntryDuplicatesToReviewListItemDialogMatchesListItem from './OnEntryDuplicatesToReviewListItemDialogMatchesListItem'
import ApDialog from '@/components/common/ApDialog'
import { formatDate } from '@/utils/dates'

export default {
  components: {
    OnEntryDuplicatesToReviewListItemDialogMatchesList,
    OnEntryDuplicatesToReviewListItemDialogMatchesListItem,
    ApDialog,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {},
    },
    matches: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      formatDate,
      recordType: false,
      selectedIds: [],
    }
  },
  computed: {
    isDuplicatedRecord() {
      return this.recordType === 'duplicate'
    },
    isNewRecord() {
      return this.recordType === 'new'
    },
    isProceedButtonDisabled() {
      return (
        !this.recordType ||
        (this.isDuplicatedRecord && this.selectedIds.length === 0)
      )
    },
    actions() {
      return [
        {
          event: this.isNewRecord ? 'confirm' : 'merge-duplicates',
          label: this.isNewRecord ? 'Confirm' : 'Next',
          disabled: this.isProceedButtonDisabled,
          color: 'primary',
        },
      ]
    },
  },
}
</script>

<style scoped>
.left-column {
  flex: 1;
}

.right-column {
  flex: 1;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
