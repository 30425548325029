<template>
  <v-card class="flex-grow-1">
    <div class="pa-4">
      <CardHeader
        title="Duplicates on Entry"
        title-tooltip="This table contains the complete list of possible duplicates on entry that must be reviewed by the admin."
        class="pb-4"
      />

      <v-divider />

      <div class="pa-4">
        <v-row justify="center">
          <v-col cols="12" sm="3">
            <v-text-field
              v-model="searchDuplicates"
              outlined
              solo
              flat
              label="Search"
              append-icon="$mdi-magnify"
            />
          </v-col>
        </v-row>

        <v-simple-table>
          <template #default>
            <thead>
              <tr>
                <th
                  v-for="(item, key) in headers"
                  :key="key"
                  class="text-left ap-dark-gray--text"
                >
                  {{ item }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in tableDuplicatesToReviewOnEntry"
                :key="index"
                class="cursor-pointer"
                @click="showDuplicateDetails(item)"
              >
                <td class="ap-red--text">
                  <v-icon color="error"> $mdi-timer-outline </v-icon>
                  {{ `${item.requiredTime} sec` }}
                </td>
                <td>{{ item.id }}</td>
                <td>{{ item.entity }}</td>
                <td>{{ item.creator }}</td>
                <td>
                  {{ formatDate(item.modified, 'MMMM dd, yyyy K:mm aaa') }}
                </td>
                <td>
                  {{ formatDate(item.created, 'MMMM dd, yyyy K:mm aaa') }}
                </td>
                <td>{{ item.name }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>

      <div class="px-4">
        <ApPagination
          :total-pages="totalPages"
          :total-items="totalItems"
          :items-per-page="itemsPerPage"
          items-designation="duplicate sets"
          is-range-pager-visible
          class="justify-center justify-sm-space-between"
          @input="$emit('page', $event)"
        />
      </div>
    </div>

    <OnEntryDuplicatesToReviewListItemDialog
      v-if="isDuplicateDetailsDialogVisible"
      v-model="isDuplicateDetailsDialogVisible"
      :item="duplicateSelected"
      :matches="duplicateMatches"
      @confirm="showCheckPermissionsDialog"
      @merge-duplicates="showMergeDuplicatesDialog"
    />

    <OnEntryDuplicatesToReviewDialog
      v-model="showDupesGridDialog"
      :dupe-sets="dialogDupeSets"
    />

    <OnEntryNewRecordPermissionsDialog
      v-if="isNewRecordPermissionsDialogVisible"
      v-model="isNewRecordPermissionsDialogVisible"
      :user-has-edit-permissions="userHasEditPermissions"
      :item="duplicateSelected"
      @cancel="backToPreviousDuplicateDetails"
      @confirm="createNewRecord"
    />
  </v-card>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import ApPagination from '@/components/common/ApPagination'
import CardHeader from '@/components/shared/CardHeader'
import OnEntryDuplicatesToReviewListItemDialog from './OnEntryDuplicatesToReviewListItemDialog'
import OnEntryDuplicatesToReviewDialog from './OnEntryDuplicatesToReviewDialog'
import OnEntryNewRecordPermissionsDialog from './OnEntryNewRecordPermissionsDialog'
import { formatDate } from '@/utils/dates'
import { getDuplicateMatches, createNewRecord } from '@/api/on-entry'

export default {
  components: {
    ApPagination,
    CardHeader,
    OnEntryDuplicatesToReviewListItemDialog,
    OnEntryDuplicatesToReviewDialog,
    OnEntryNewRecordPermissionsDialog,
  },
  data() {
    return {
      formatDate,
      headers: [
        'Required Time',
        'Record ID',
        'Entity',
        'Creator',
        'Modified',
        'Created',
        'Name',
      ],
      totalPages: 1,
      totalItems: 12,
      itemsPerPage: 10,
      page: 1,
      searchDuplicates: '',
      isNewRecordPermissionsDialogVisible: false,
      isDuplicateDetailsDialogVisible: false,
      duplicateSelected: null,
      duplicateMatches: [],
      showDupesGridDialog: false,
      dialogDupeSets: [],
    }
  },
  computed: {
    ...mapState('on-entry', ['tableDuplicatesToReviewOnEntry']),
    ...mapGetters('on-entry', ['userHasEditPermissions']),
  },
  mounted() {
    this.getDuplicatesToReview()
  },
  methods: {
    ...mapActions('on-entry', ['getDuplicatesToReview']),
    async showDuplicateDetails(item) {
      this.duplicateSelected = item
      this.isDuplicateDetailsDialogVisible = true
      this.duplicateMatches = await getDuplicateMatches()
    },
    showMergeDuplicatesDialog() {
      this.isDuplicateDetailsDialogVisible = false
      this.showDupesGridDialog = true
      this.dialogDupeSets = [this.duplicateSelected]
    },
    showCheckPermissionsDialog() {
      this.isDuplicateDetailsDialogVisible = false
      this.isNewRecordPermissionsDialogVisible = true
    },
    backToPreviousDuplicateDetails() {
      this.showDuplicateDetails(this.duplicateSelected)
      this.isNewRecordPermissionsDialogVisible = false
    },
    createNewRecord() {
      createNewRecord(this.duplicateSelected)
      this.isNewRecordPermissionsDialogVisible = false
    },
  },
}
</script>

<style scoped>
.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td:not(.v-data-table__mobile-row):not(:last-child),
.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th:not(:last-child) {
  border-right: none;
}
</style>
