<template>
  <div
    class="d-flex align-center flex-1 cursor-pointer"
    :class="outline ? 'rounded border-all px-2 py-2' : ''"
    @click="$refs.datePickerRef.openMenu()"
  >
    <DatePicker ref="datePickerRef" v-model="date" />
    <span
      class="ap-dark-gray--text font-weight-black caption"
      :class="outline ? 'mr-3' : ''"
      >This week</span
    >
    <v-icon>$mdi-chevron-down</v-icon>
  </div>
</template>

<script>
import DatePicker from '@/components/shared/DatePicker'

export default {
  components: { DatePicker },
  props: {
    value: {
      type: String,
      default: '',
    },
    outline: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      date: '',
    }
  },
  watch: {
    value: function (val) {
      this.date = val
    },
    date: function (newVal, oldVal) {
      if (newVal && this.value !== newVal && newVal !== oldVal) {
        this.$emit('input', newVal)
      }
    },
  },
  mounted() {
    this.date = this.value
  },
  methods: {
    dateChanged() {
      this.$emit('input', this.date)
    },
  },
}
</script>

<style scoped>
.border-all {
  border: 1px solid #dddbda !important;
}
</style>
