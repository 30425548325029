<template>
  <div class="d-flex align-center">
    <div class="text-h5 font-weight-black">
      {{ title }}
    </div>
    <v-tooltip v-if="titleTooltip" top content-class="tooltip elevation-4">
      <template #activator="{ on, attrs }">
        <v-icon right small color="ap-gray" v-bind="attrs" v-on="on">
          $mdi-information-outline
        </v-icon>
      </template>
      <span>
        {{ titleTooltip }}
      </span>
    </v-tooltip>
    <v-spacer />
    <date-picker-btn v-if="calendar" v-model="date" />
  </div>
</template>

<script>
import DatePickerBtn from '@/components/shared/DatePickerBtn'

export default {
  components: {
    DatePickerBtn,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    calendar: {
      type: Boolean,
      default: false,
    },
    titleTooltip: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      date: '',
    }
  },
  watch: {
    value(value) {
      this.date = value
    },
    date(value) {
      this.$emit('input', value)
    },
  },
  mounted() {
    this.date = this.value
  },
}
</script>

<style lang="scss" scoped>
.title {
  line-height: 1;
}

.tooltip {
  background-color: white;
  color: grey;
}
</style>
