<template>
  <DupesGridDialog
    v-if="showDialog"
    v-model="currentDupeSet"
    title="Duplicate Set"
    :records="dupeSets"
    :footer-details="currentDupeSetDetails"
    @close="showDialog = false"
  />
</template>

<script>
import DupesGridDialog from '@/components/dupesgrid/DupesGridDialog'
import { formatDate } from '@/utils/dates'

export default {
  components: {
    DupesGridDialog,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    dupeSets: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      formatDate,
      showDialog: false,
      currentDupeSet: {},
    }
  },
  computed: {
    currentDupeSetDetails() {
      const items = []

      if (this.currentDupeSet.id) {
        items.push({
          label: 'Set ID:',
          value: this.currentDupeSet.id,
        })
      }

      if (this.currentDupeSet.name) {
        items.push({
          label: 'Owner:',
          value: this.currentDupeSet.name,
        })
      }

      if (this.currentDupeSet.created) {
        items.push({
          label: 'Created:',
          value: formatDate(this.currentDupeSet.created),
        })
      }

      if (this.currentDupeSet.modified) {
        items.push({
          label: 'Modified:',
          value: formatDate(this.currentDupeSet.modified),
        })
      }

      return items
    },
  },
  watch: {
    showDialog(value) {
      this.$emit('input', value)
    },
    value(value) {
      this.showDialog = value
    },
  },
}
</script>
