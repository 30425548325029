<template>
  <v-container fluid>
    <div class="d-flex justify-end mb-5">
      <div class="date-picker-container">
        <DateRangePicker @change="onDateChange" />
      </div>
    </div>

    <OnEntryDuplicatesToReviewList />
  </v-container>
</template>

<script>
import OnEntryDuplicatesToReviewList from './components/duplicates-to-review/OnEntryDuplicatesToReviewList'
import DateRangePicker from '@/components/shared/DateRangePicker'

export default {
  components: {
    OnEntryDuplicatesToReviewList,
    DateRangePicker,
  },
  data() {
    return {
      date: null,
    }
  },
  methods: {
    onDateChange(payload) {
      this.date = {
        start: payload.startDate,
        end: payload.endDate,
      }
    },
  },
}
</script>

<style scoped>
.date-picker-container {
  width: 270px;
}
</style>
